import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PostsSidebar from '../components/postsSidebar'

const BlogPostTemplate = ({ data, location, pageContext }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const siteUrl = data.site.siteMetadata.siteUrl
  // const { previous, next } = pageContext
  // const linkUrl = `${siteUrl}${post.fields.slug}`
  //
  // const [tags, setTags] = useState([])

  // useEffect(() => {
  //   setTags(_.shuffle(post.frontmatter.tags))
  // }, [])

  return (
    <Layout
      location={location}
      title={siteTitle}
      postTitle={post.frontmatter.title}
    >
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={
          post.frontmatter.featuredImage.publicURL &&
          `${siteUrl}${post.frontmatter.featuredImage.publicURL}`
        }
      />

      <div id="blog-page" className="single-page container">
        <div className="col-md-9 blog-home">
          <div className="row">
            <div className="blog-post post-main">
              <h3>{post.frontmatter.title}</h3>

              <div className="post-info">
                <p>
                  <i className="fa fa-clock-o" />
                  {post.frontmatter.date}
                </p>
                <p>
                  <i className="fa fa-user" />
                  by <a href="#">Naomi Nadiv</a>
                </p>
              </div>

              {
                post.frontmatter.skipFeatured !== 'yes' &&
                <img
                  className="img-responsive"
                  src={post.frontmatter.featuredImage.publicURL}
                  alt=""
                />
              }

              <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
          </div>
        </div>
        <PostsSidebar />
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    allCategoriesAndTags: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
      filter: { frontmatter: { isVisible: { ne: "no" } } }
    ) {
      edges {
        node {
          frontmatter {
            category
            tags
          }
        }
      }
    }
    mostResentPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
      filter: { frontmatter: { isVisible: { ne: "no" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        skipFeatured
        id
        title
        date(formatString: "MMMM DD, YYYY")
        description
        category
        tags
        featuredImage {
          publicURL
        }
      }
    }
  }
`
